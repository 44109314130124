/* eslint-disable default-case */
import React from 'react';
import * as Api from '../services/api/common-api.js'
import DidoDrawer from '../components/Drawer.js'
import {GreenButton} from '../components/GreenButton.js'
import { withSnackbar } from 'notistack';
import { connect } from "react-redux";
import actions from "../actions.js";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Grid, Paper, styled, Typography } from '@mui/material';
import Markdown from 'react-markdown'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SNACK_SUCCESS } from './../constants/common';
import rehypeRaw from 'rehype-raw'
import { Button } from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const ActiveTile = styled(Paper)(({ theme }) => ({    
    height: '9vh',
    verticalAlign: 'middle',
    background: '#fff',
    width:'100%',
    overflow: 'clip', 
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    alignContent: 'center',    
    border: '1px solid #ccc',
    '&:hover': {
        background: '#ccc'
    },
    marginTop: '0.5vw',
}));

const REGEXP_IMG_MARKDOWN = /!\[[^\]]*\]\((?<filename>.*?)(?=\"|\))(?<optionalpart>\".*\")?\)/

class AssistantPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            tiles: [], 
            dialogOpen: false, 
            loading : false, 
            assistantMessage: null, 
            isRunCancelled: false, 
            responseFinished: false,
            usedTools: [],
            toolDialogOpen: false,
            toolDialogItem: null
        };
        this.myRef = React.createRef()
    }

    componentDidMount() {
        Api.getTiles().then(res => {
            this.setState({ tiles: res.data});
        })
    }

    executeScroll = () => (this.myRef && this.myRef.current) ? this.myRef.current.scrollIntoView({  block: "end", inline: "nearest", behaviour: "smooth"}) : ''

    onProgress = async (p) => {
        //console.log(p.currentTarget.responseText);
        const jsonChunks = p.currentTarget.responseText.split("^!");
        let responseText = "";
        let fileLinks = [];
        let usedTools = [];        

        for (var jsonChunk of jsonChunks) {
            if (!jsonChunk) {
                continue;
            }            
            //console.log(jsonChunk);
            const chunk = JSON.parse(jsonChunk);
            

            if (chunk.Text) {
                responseText += chunk.Text
            }

            if (chunk.FileLinks && chunk.FileLinks.length > 0) {
                console.log(chunk.FileLinks);
                fileLinks.push(...chunk.FileLinks);
                console.log(fileLinks);
            }

            if (chunk.ChatId) {
                for (var link of fileLinks) {
                    link = link.replace("CHAT_ID", chunk.ChatId);
                    //responseText = responseText.replace(REGEXP_IMG_MARKDOWN, "<img src='" + link + "' />");
                    let isLinkInTextExist = false;
                    responseText = responseText.replace(REGEXP_IMG_MARKDOWN, () => {
                        isLinkInTextExist = true;
                        return "<img src='" + link + "' />";
                    })
                    if (!isLinkInTextExist) {
                        responseText += "<img src='" + link + "' />";
                    }
                }
            }

            if (chunk.UsedTools && chunk.UsedTools.length > 0) {
                console.log(chunk.UsedTools);
                usedTools.push(...chunk.UsedTools);
                
            }

            if (chunk.IsEnd === true) {
                console.log('END')
                this.setState({ loading: false, responseFinished: true });
            }
        }
             
        this.setState({assistantMessage: responseText });
        this.setState({ usedTools: usedTools});
        this.executeScroll();
        //console.log(responseText);
        //console.log(this.state.assistantMessage);
    }    

    handleTileClick = async (tile) => {
        await this.setState({assistantMessage : 'Analyzing question...\r\n', loading: true, responseFinished: false, usedTools: [] });
        this.forceUpdate();
        const teamName = this.props.currentUser?.Teams.find(t => t.Id == this.props.currentTeam)?.Name;
        Api.sendTileMessageToFlowise({
            ClickedTileGuid: tile.Id,
            TeamName: teamName
        }, this.onProgress)
        .finally(() => this.setState({loading: false}))
       .catch(err => { if (this.state.isRunCancelled) Api.logger.errorWithSnackbar(`Error while assistant requesting, please contact support`, this, err)})
    }

    handleClipboardClick = async () => {
        navigator.clipboard.writeText(this.state.assistantMessage).then(() => {
            this.props.enqueueSnackbar("Text has been copied to the clipboard", SNACK_SUCCESS);
        });
    }

    handleShowDialog = async (tool) => await this.setState({ toolDialogOpen: true, toolDialogItem: tool });

    render() {
        var buttonStyle = {};
        if (this.state.loading) {
            buttonStyle = {
                pointerEvents: 'none',
                background: '#ccc'
            }
        }
        //console.log(this.state.assistantMessage);
        let message = this.state.assistantMessage + (!(this.state.responseFinished) ? ' <span style="animation: blinker .75s linear infinite; font-size: 24px; color:#ddd">' + String.fromCharCode(9632) + '</span>' : ''); //

        
            return (
            <div style={{ transform: 'translateZ(0px)', flexGrow: 1, }} >
                <DidoDrawer style={{ zIndex: 2000 }} pageTitle='Team assistent' />
                <Grid container style={{ marginTop: '75px', marginLeft: '40px', width: '95vw' }}>
                    <Grid item xs={3} sx={{ overflowY: 'auto', maxHeight: '90vh', marginTop: '0.5vw'}}>
                        {
                            this.state.tiles.map(tile => {
                                return (
                                    <ActiveTile onClick={() => this.handleTileClick(tile)} elevation={1} style={buttonStyle}>
                                        <Grid container>
                                            <Grid item xs={2} sx={{ textAlign: 'center', verticalAlign: 'middle', alignContent: 'center' }}>
                                                {tile.Avatar ?
                                                    <img src={tile.Avatar} width={40} height={40} alt="" style={{ marginTop: '0.5vh' }} /> : // sx={{ width: 32, height: 32 }} 
                                                    <AutoFixHighIcon sx={{ width: 24, height: 24 }} />
                                                }
                                            </Grid>
                                            <Grid item xs={10} alignContent={'center'} >
                                                <Typography>{tile.Name}</Typography></Grid>
                                        </Grid>
                                    </ActiveTile>
                                );
                            })
                        }
                    </Grid>
                    <Grid item xs={9}>
                        <Paper sx={{ overflowY: 'auto', height: '85vh', padding: '1vw', margin: '1vw', border: '1px solid #ccc' }}>
                            {
                                this.state.assistantMessage ?
                                        <Grid container >
                                            <Grid item xs={10}></Grid>
                                            <Grid item xs={2}>
                                                <GreenButton 
                                                startIcon={<ContentCopyIcon/>} 
                                                sx={{width: 'fit-content'}}
                                                onClick={this.handleClipboardClick}
                                                >
                                                    Copy to clipboard</GreenButton>
                                                    </Grid>
                                            <Grid item xs={10}>
                                                <>
                                                    <Markdown
                                                        rehypePlugins={[rehypeRaw]}
                                                    >{message}
                                                    </Markdown>
                                                   
                                                </>
                                            </Grid>
                                            <Grid item xs={2}></Grid>
                                            <Grid item xs={10}> {

                                                this.state.usedTools.map((tool) => {
                                                    console.log(tool);
                                                    return (
                                                        <Button
                                                            variant="outlined"

                                                            sx={{
                                                                mr: 2, backgroundColor: "white",
                                                                color: "#4AB897", borderRadius: '16px',
                                                                border: '1px solid #6AD8B7',
                                                                "&:hover": {
                                                                    border: '1px solid #4AB897',                                                    
                                                                },
                                                                textTransform: 'none'
                                                            }}
                                                            startIcon={<BuildIcon />}
                                                            onClick={() => this.handleShowDialog(tool)}
                                                        >{tool.Name}</Button>
                                                    )
                                                })
                                            }</Grid>
                                            <Grid item xs={2}>
                                                {
                                                    this.state.responseFinished ?
                                                        <GreenButton
                                                            startIcon={<ContentCopyIcon />}
                                                            sx={{ width: 'fit-content' }}
                                                            onClick={this.handleClipboardClick}
                                                        >
                                                            Copy to clipboard</GreenButton>
                                                        :
                                                        <></>
                                                }
                                            </Grid>
                                            <Grid item xs={10}><div ref={this.myRef}></div ></Grid>
                                            <Grid item xs={2}></Grid>
                                        </Grid>
                                        :
                                        <div>Click on the left-side buttons to get messages from assistant</div>
                            }
                        </Paper>
                    </Grid>
                </Grid>               
                <Dialog
                    open={this.state.toolDialogOpen}
                    onClose={() => this.setState({ toolDialogOpen: false })}
                    maxWidth="xl"
                    fullWidth
                >
                    <DialogTitle>
                        <Grid container>
                            <Grid item xs={10} style={{ alignSelf: 'center' }}>{this.state.toolDialogItem?.Name}</Grid>
                            <Grid item xs={2} style={{ textAlign: 'right' }}>
                                <IconButton onClick={() => this.setState({ toolDialogOpen: false })}><CloseIcon /></IconButton>
                            </Grid>
                        </Grid>
                        </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Grid container>
                                <Grid item xs="1">Input:</Grid>
                                    <Grid item xs="11" sx={{ whiteSpace: "pre-line" }}>
                                        <pre><code>
                                            {
                                                this.state.toolDialogItem?.Input.length > 0 ?
                                                    this.state.toolDialogItem?.Input.replaceAll("\\n", "\n") : ''
                                            }
                                        </code></pre>
                                    </Grid>
                                <Grid item xs="1">Output:</Grid>
                                    <Grid item xs="11" sx={{ whiteSpace: "pre-line" }}>
                                        <pre><code>
                                            {
                                                this.state.toolDialogItem?.Output.length > 0 ?
                                                    this.state.toolDialogItem?.Output.replaceAll("\\n", "\n") : ''
                                            }
                                        </code></pre>
                                    </Grid>
                            </Grid>                             
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>                        
                        <Button onClick={() => this.setState({ toolDialogOpen: false })} autoFocus>
                        Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>);
    }
}

export default connect((state) => ({        
        currentOrg: state.didoReducer.currentOrg,
        currentTeam: state.didoReducer.currentTeam,
        currentUser: state.didoReducer.currentUser
    }), actions)(withSnackbar(AssistantPage));